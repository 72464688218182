/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


exports.onInitialClientRender = () => {
  //document.addEventListener("DOMContentLoaded", function(event) {
		(function() {
			var jo = document.createElement('script'); jo.type = 'text/javascript'; jo.async = true;
			jo.src = 'https://d3g1xorggx1cts.cloudfront.net/journey.js';
			var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(jo, s);
		})();
//});
}